import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 id="2=Skillshare-Profiles">2 Skillshare Profiles</h2>
    <p>{`Yup you read that correctly! If you want to learn web development then you
should really check out these two teachers. They are great!`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.skillshare.com/user/aneagoie"
      }}>{`Andrei Neagoie`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d252cb5f0be44731e44f99c0c4de8e4f/9685e/andrei-skillshare.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABoUlEQVQoz11Su27bQBD0x+Yr/BtJY6cJ0qQNYMCtKwOBA0sWKYqUSPH9Jo8nvvSoxrtHMxZSLOfAPczOzN5N3/cYjyfUeYSnx98YeommkTC2NrwwxjAM6LruX7UtVzthN52Zg+8x3vT083S+ILGf8eP2C6RwcDh02BDh1nHhBRH8MFLIFcYJVYokyxElGeI0o0FXhDz1cjnjVdPw8/s9XUjUdH1jEakD3dxiZVh4W5tYWzuYOwe7vaeGMZq7PcqqxjiOE+FsKSsKxHmmmkI0+LvUsNQ3RGbCsGxoRLrQDUX8tt5goRmqb9l7FdGnZfqcjkc4Xoxvvx4gagkpKUNSwzmyiihJEUSxwtnyhAnSvEAjD9eWe5wvA17+pLj76iFLOmq0sInIdn2FrGK2yue5OBLb9SBI4TgTzpZdP4A80IZJHdt+Waw+LE/5LfXJ4kpZNpX919VaRVEL8ZnhvPK6rhEEIaqqoomNCp0t+fR05g171Hf9UCFvNyO7TMaChmuFfBDUKMuSiAU9mxZ7L1DFRHlRkuoKRVkpZAeScvv/DTK+AyW8lHLCr1H2AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Andrei Neagoie Profile",
            "title": "Andrei Neagoie Profile",
            "src": "/static/d252cb5f0be44731e44f99c0c4de8e4f/5a190/andrei-skillshare.png",
            "srcSet": ["/static/d252cb5f0be44731e44f99c0c4de8e4f/772e8/andrei-skillshare.png 200w", "/static/d252cb5f0be44731e44f99c0c4de8e4f/e17e5/andrei-skillshare.png 400w", "/static/d252cb5f0be44731e44f99c0c4de8e4f/5a190/andrei-skillshare.png 800w", "/static/d252cb5f0be44731e44f99c0c4de8e4f/c1b63/andrei-skillshare.png 1200w", "/static/d252cb5f0be44731e44f99c0c4de8e4f/9685e/andrei-skillshare.png 1336w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://www.skillshare.com/user/dtkatz"
      }}>{`David Katz`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8ed5ff2fa9b42d513e559ac06c3b0853/4e814/David-katz-skillshare.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAB+UlEQVQoz4VSTWsUQRDNLxIvghDwB3jNRSH+AW/+AsWDCx7EoEIOCsGAAcWDgpfVmyBoMJtRs5vZZdednpnd3pmdr56Pnh6yPKs7Ju7m4uFRNT3Vr6pfvbWiKJDnOcqyRE5IKZdVhSRNMY9iZEJA1/wPmkPHNZMQciJpv3+L18+3ESYxPH+CI3uAcB6ZYk2cZQJCx2VkGcRfMl1nCEv6mBc5Xj64i73NWxi6DoJwjtGYwfF8jJkLRlHDcT2CD5caniEl0hVCM2Ut4R1Y4O8+oqJp/SmHPRiiR+jSpL+O+zRxHyOHYcI5+Cw4RRAgTpILhFoHkaPPHFhsBEEdkySl4vBcQ1nWqKsaMqdIuZKNOauEJKIVDQujQVPXOOwO0f7cQaMUPdfB/sEhXObRRBP8+G3h59jCILPR9Y/QGXxHj3fRT44RpzHJVv6bUDOntFXGONKoNA08z8OXXgdtax+2b2OzdQOXr1zCzfsbaH29h/WNq7h2fR133tyGmzDIQq4S6m25ROL6nnluQNtlYYgpNXKDKXa+vcCTvcd4uNvCK2sX25+eYWvnEZ5+2AJPOaqiOiU89yFFbYmIvKd9OCP9+Iw0zLTGJTXimMYRGpwgTQSGJIVQFWTTkO0u+nDJmGe5lBKLxQInhJr01VC1glKN+adIZ1U35nz5/h/PRt0kRGw3PAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Andrei Neagoie Profile",
            "title": "Andrei Neagoie Profile",
            "src": "/static/8ed5ff2fa9b42d513e559ac06c3b0853/5a190/David-katz-skillshare.png",
            "srcSet": ["/static/8ed5ff2fa9b42d513e559ac06c3b0853/772e8/David-katz-skillshare.png 200w", "/static/8ed5ff2fa9b42d513e559ac06c3b0853/e17e5/David-katz-skillshare.png 400w", "/static/8ed5ff2fa9b42d513e559ac06c3b0853/5a190/David-katz-skillshare.png 800w", "/static/8ed5ff2fa9b42d513e559ac06c3b0853/c1b63/David-katz-skillshare.png 1200w", "/static/8ed5ff2fa9b42d513e559ac06c3b0853/4e814/David-katz-skillshare.png 1335w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This whole web-development class sharing leads nicely to the next topic`}</p>
    <h2 id="an-announcement">An announcement</h2>
    <p>{`People that teach how to code know how to code. They have been doing this for
ages, but with experience, the sense of being a beginner fades away. You hear
people say just dabble around with the code and you will learn how to code. I
myself am guilty of that in my article`}</p>
    <a href="/Articles/The-myth-about-coding">The Myth about Programming</a>. So now
I am learning JS and more specifically React myself. So I have thought to
document my experience with this. I literally do not know anything about JS. So
come along with me and see how I dabble around and figure things out
    <p>{`At the end we will see judge my learning by converting this very website into
a React app with a working search feature which should allow you to search
through the names of the articles. I will also use Google Tag Manager for
this.`}</p>
    <h2>Tweets</h2>
    <blockquote className="twitter-tweet">
  <p lang="en" dir="ltr">
    Checkbox Strikethrough: If you like our todo&#39;s to be crossed out, this
    roam/js code snippet will help. Puts line through completed tasks. Enhanced
    for CSS modders. Inspired by
    <a href="https://twitter.com/calhistorian?ref_src=twsrc%5Etfw">
      @calhistorian
    </a>
    <a href="https://twitter.com/CatoMinor3?ref_src=twsrc%5Etfw">@CatoMinor3</a>
    (code efficient)
    <a href="https://t.co/szPJ9tvG58">https://t.co/szPJ9tvG58</a>
    <a href="https://twitter.com/RoamResearch?ref_src=twsrc%5Etfw">
      @RoamResearch
    </a> <a href="https://twitter.com/hashtag/roamcult?src=hash&amp;ref_src=twsrc%5Etfw">
      #roamcult
    </a> <a href="https://twitter.com/hashtag/roamosphere?src=hash&amp;ref_src=twsrc%5Etfw">
      #roamosphere
    </a> <a href="https://t.co/dmXIKSxfIB">pic.twitter.com/dmXIKSxfIB</a>
  </p>
  &mdash; RoamHacker 👨‍🔧 ((roam42.com)) (@roamhacker)
  <a href="https://twitter.com/roamhacker/status/1304269711065133056?ref_src=twsrc%5Etfw">
    September 11, 2020
  </a>
    </blockquote>
    <p>{`I have always been wanting to do this. I thought of taking up this challenge
myself but while casually browising twitter I see this!`}</p>
    <blockquote className="twitter-tweet">
  <p lang="en" dir="ltr">
    orphans is a rarely used function in Roam that shows you list of blocks that
    can’t be found on a page because they lost their parent. Sometimes surfaces
    some old gems.
    <a href="https://twitter.com/RoamResearch?ref_src=twsrc%5Etfw">
      @RoamResearch
    </a>
    <a href="https://twitter.com/hashtag/roamcult?src=hash&amp;ref_src=twsrc%5Etfw">
      #roamcult
    </a>
    <a href="https://twitter.com/hashtag/roamosphere?src=hash&amp;ref_src=twsrc%5Etfw">
      #roamosphere
    </a>
    <a href="https://t.co/OXkbz8wUmQ">pic.twitter.com/OXkbz8wUmQ</a>
  </p>
  &mdash; RoamHacker 👨‍🔧 ((roam42.com)) (@roamhacker)
  <a href="https://twitter.com/roamhacker/status/1304041071291310080?ref_src=twsrc%5Etfw">
    September 10, 2020
  </a>
    </blockquote>
    <p>{`Orphan blocks! So this has only happened to me once, I typed up a large
research document and puff Roam crashed and the document was deleted. And
now, I tried orphans and I got those blocks back! Only if I had known this a
month ago, I wouldn't have had to do everything again`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      